import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Blog } from '../classes/blog';
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class BlogService {

  public Blogs

  constructor(private http: HttpClient) { }

  /*
    ---------------------------------------------
    ---------------  Blogs  -------------------
    ---------------------------------------------
  */

  // Blogs
  private get blogs(): Observable<Blog[]> {
    this.Blogs = this.http.get<Blog[]>(environment.apiUrl + '/blogsFrontend').pipe(map(data => data));
    // this.Blogs = this.http.get<Blog[]>('assets/mockData/blogmock.json').pipe(map(data => data));
    this.Blogs.subscribe(next => { localStorage['blogs'] = JSON.stringify(next) });
    return this.Blogs = this.Blogs.pipe(startWith(JSON.parse(localStorage['blogs'] || '[]')));
  }

  // Get Blogs
  public get getBlogs(): Observable<Blog[]> {
    return this.blogs;
  }

  // Get Blogs By Slug
  public getBlogBySlug(slug: string): Observable<Blog> {
    return this.blogs.pipe(map(items => {
      return items.find((item: any) => {
        return item.title.replace(' ', '-') === slug;
      });
    }));
  }



  /*
    ---------------------------------------------
    ------------- Blogs Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 8;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
