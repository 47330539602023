<owl-carousel-o [options]="BlogSliderConfig" class="no-arrow">
  <ng-container *ngFor="let blog of blogs; let i " class="tumitester">
    <ng-template carouselSlide>
      <div class="col-md-12 tumitester">
        <a [routerLink]="['/pages/blog/', blog.title.replace(' ', '-')]">
          <div class="classic-effect">
            <img [src]="blog?.media[0]?.medium?.url " class="img-fluid" alt="blog">
            <span></span>
          </div>
        </a>
        <div class="blog-details">
          <h4>{{blog.date}}</h4>
          <a [routerLink]="['/pages/blog/', blog.title.replace(' ', '-')]">
            <p>{{blog.title}}</p>
          </a>
          <hr class="style1">
          <h6> {{ 'by: ' +blog.author}}</h6>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>