import { Component, OnInit } from '@angular/core';
import { AircraftService } from '../../services/aircraft.service';
import { CarService } from '../../services/car.service';
import { HouseService } from '../../services/house.service';
import { JewelleryService } from '../../services/jewellery.service';
import { YachtService } from '../../services/yacht.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  type: string
  searchResults: any
  show: boolean = false;
  constructor(
    private yatchService: YachtService,
    private carService: CarService,
    private airCrafts: AircraftService,
    private jeweleryService: JewelleryService,
    private houseService: HouseService
  ) { }

  ngOnInit(): void {
  }

  search(event) {
    const searchValue = event?.target.value;
    let result = (arr, str) => {
      return arr?.filter(x => Object.values(x)
        .join(' ')
        .toLowerCase()
        .includes(str.toLowerCase()))
    }
    // let result
    // switch (this.type) {
    //   case "yacht" || "aircrafts":
    //     result = this.searchResults.filter(o =>  o.mode.includes(searchValue));
    //     result = (arr, str) => {
    //       return arr.filter(x => Object.values(x)
    //        .join(' ')
    //        .toLowerCase()
    //        .includes(str.toLowerCase()))
    //      }
    //     break;
    //   case "car" || "jewellery":
    //     result = this.searchResults.filter(o => o.title.includes(searchValue));
    //     break;
    //   default:
    //     break;
    // }
    // // console.log(result(this.searchResults, searchValue))
    this.searchResults = result(this.searchResults, searchValue);
  }

  catSelect(event) {

    const value = event.target.value;
    this.show = !this.show;
    switch (value) {
      case "1":
        this.type = "yacht"
        this.yatchService.getYachts.subscribe((data) => {
          this.searchResults = data
        })
        break;

      case "2":
        this.type = "car"
        this.carService.getCars.subscribe((data) => {
          this.searchResults = data
        })
        break

      case "3":
        this.type = "aircrafts"
        this.airCrafts.getAircrafts.subscribe((data) => {
          this.searchResults = data
        })
        break
      case "4":
        this.type = "jewellery"
        this.jeweleryService.getJewellery.subscribe((data) => {
          this.searchResults = data
        })
        break
      case "5":
        this.type = "houses"
        this.houseService.getHouses.subscribe((data) => {
          this.searchResults = data
        })
        break
      default:
        break;
    }
  }

}
