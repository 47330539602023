import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { $ } from 'protractor';
import { StaffService } from 'src/app/shared/services/staff.service';
import { FinanceLocalStorageService } from 'src/app/shared/_services/finance-local-storage.service';

@Component({
  selector: 'finance-representitive-modal',
  templateUrl: './finance-representitive.component.html',
  styleUrls: ['./finance-representitive.component.scss']
})
export class FinanceRepresentitiveComponent implements OnInit {

  @ViewChild("financeSalesPerson") FinanceSalesPersonModal: TemplateRef<any>;

  public closeResult: string;
  public currdate: any;
  public setDate: any;
  public brandsList: any;
  public salesPeople: [];
  public salesPerson: any;
  dropdown;
  public loading: boolean;
  public valid: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public staffService: StaffService,
    private financeStorageService: FinanceLocalStorageService) {
  }

  ngOnInit(): void {
    this.staffService.getDealerAccounts()
      .subscribe(res => {
        this.brandsList = res;
      });

  }

  ngAfterViewInit(): void {
    if (localStorage.getItem("financeSalesPerson") !== 'true') {
      this.openModal();
    }
  }
  ngAfterContentChecked() {
    // console.log('conrent changr');
    // this.financeStorageService.financePersonObjValue.subscribe((val) => {
    //   if (!val || val == null || val == "") {
    //     this.openModal();
    //   }
    // })
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.FinanceSalesPersonModal, {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        centered: true,
        windowClass: 'bd-example-modal-md theme-modal agem'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  brandEvent($event: any) {
    const staffId = $event.value
    this.loading = true;
    this.staffService.getDealerStaffAccount(staffId)
      .subscribe(res => {
        this.loading = false;
        this.salesPeople = res;
        this.dropdown = true;
      })
  }

  salesPersonEvent($event) {
    this.salesPerson = $event.value;
    this.valid = true;
    // this.modalService.dismissAll();
  }

  submit() {
    localStorage.setItem('financeSalesPerson', 'true')
    const newObj = JSON.stringify(this.salesPerson);
    this.financeStorageService.financePersonObj = newObj;
    // localStorage.setItem('financePersonObj', JSON.stringify(this.salesPerson));
    this.modalService.dismissAll();
    this.financeStorageService.financePersonObjValue.subscribe((val) => {
      if (!val || val == null || val == "") {
        this.dropdown = false;
        this.openModal();
      }
    })
  }

  ngOnDestroy() {

  }

}
