import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class NeedsAnalysesService {

  private apiServer = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  create(needsAnalyses: any, link?): Observable<any> {
    return this.httpClient.post<any>(this.apiServer + link, JSON.stringify(needsAnalyses), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  getById(id): Observable<any> {
    return this.httpClient.get<any>(this.apiServer + '/needsAnalyses/' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getByDealerId(id): Observable<any> {
    return this.httpClient.get<any>(this.apiServer + '/needsAnalyses/dealer' + id)
    .pipe(
      catchError(this.errorHandler)
    );
  };
  
  getByAllocationId(_userId?): Observable<any> {
    let userId;
    if(_userId){
       userId = _userId
    } else {
      userId = JSON.parse(localStorage.getItem('currentCustomer')).id;
    };
    return this.httpClient.get<any>(this.apiServer + '/needsAnalyses/allocation/' + userId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getAll(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.apiServer + '/needsAnalyses/')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(id, product): Observable<any> {
    return this.httpClient.put<any>(this.apiServer + '/needsAnalyses/' + id, JSON.stringify(product), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(id){
    return this.httpClient.delete<any>(this.apiServer + '/needsAnalyses/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  errorHandler(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     // console.log(errorMessage);
     return throwError(errorMessage);
  }
}
