<!--modal popup start-->
<ng-template class="theme-modal" #enquiryModal let-modal>
    <div class="modal-content">
        <div class="modal-body modal13">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="theme-card">
                                <h2>Get in touch about the {{header}} today!</h2>
                                <h4>Fill your details below and we'll call you right back!</h4>
                                <form id="theme-form" [formGroup]="enquiryForm">
                                    <div>
                                        <label for="fname">Name</label>
                                        <input type="text" name="fname" class="form-control"
                                            formControlName="customer_name" placeholder="Name">
                                        <br />
                                        <label for="fname">Surname</label>
                                        <input type="text" name="fname" class="form-control"
                                            formControlName="customer_surname" placeholder="Surname">
                                        <br />
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" formControlName="customer_email"
                                            placeholder="Last Name">
                                        <br />
                                        <label for="fname">Phone Number</label>
                                        <input type="number" class="form-control" formControlName="customer_number"
                                            placeholder="Phone Number">
                                        <br />
                                        <label for="nowOrLater">When are you looking to buy</label>
                                        <select name="nowOrLater" class="form-control" (change)="buyNowOrLaterEvent($event.target.value)">
                                            <option value="buynow">Now</option>
                                            <option value="justenquiring">I'm Just Enquiring</option>
                                        </select>
                                        <br />
                                        <ng-container *ngIf="showDescriptionBox">
                                            <label  for="description">Anything you'd like to know?</label>
                                            <textarea name="description" formControlName="enquiry_description"
                                                class="form-control" placeholder="Anything you'd like to know?"
                                                id="description" rows="6"></textarea>
                                            <br />
                                        </ng-container>
                                        <label for="salesPersonEvent">Please select a sales person to assist you</label>
                                        <select name="salesPersonEvent" class="form-control" (change)="salesPersonEvent($event.target.value)">
                                            <option value="any">Any</option>
                                            <option *ngFor="let person of salesPeople" [value]="person.id">
                                                {{person.name}}</option>
                                        </select>
                                        <!-- <mat-select>
                                            <mat-option *ngFor="let person of salesPeople" [value]="person.id">
                                            <img src='{{person?.media[0]?.medium?.url}}'> {{person.name}}
                                            </mat-option>
                                          </mat-select> -->
                                        <br />
                                        <button type="submit" (click)="submitForm()" class="btn btn-solid">
                                            submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->