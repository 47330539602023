import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { VideoModalComponent } from "../../shared/components/modal/video-modal/video-modal.component";
import { EnquiryModalComponent } from 'src/app/shared/components/modal/enquiry-modal/enquiry-modal.component';
import { Product } from '../../shared/classes/product';
import { Blog } from '../../shared/classes/blog';
import { ProductService } from '../../shared/services/product.service';
import { BlogService } from "../../shared/services/blog.service";


@Component({
  selector: 'app-furniture',
  templateUrl: './furniture.component.html',
  styleUrls: ['./furniture.component.scss']
})
export class FurnitureComponent implements OnInit, OnDestroy {

  @ViewChild("enquiryModal") EnquiryModal: EnquiryModalComponent;
  @ViewChild("videoModal") VideoModal: VideoModalComponent;


  public themeLogo: string = 'assets/OriginalDCIcon.webp'; // Change Logo

  public products: Product[] = [];
  public productCollections: any[] = [];
  public blogs: Blog[] = []
  _videoLink = 'https://www.youtube.com/embed/PsSKKitcc_4'

  constructor(
    public productService: ProductService,
    public blogService: BlogService) {
    // this.productService.getProducts.subscribe(response => {
    //   // console.log(response);
    //   this.products = response.filter(item => item.type == 'furniture');
    //   // Get Product Collection
    //   this.products.filter((item) => {
    //     item.collection.filter((collection) => {
    //       const index = this.productCollections.indexOf(collection);
    //       if (index === -1) this.productCollections.push(collection);
    //     })
    //   })
    // });
    // LIST BLOGS
    this.blogService.getBlogs.subscribe(response =>{
      this.blogs = response;
    })
  }

  // Collection banner TODO: Main Sliders
  public sliders = [
    {
      title: '2021',
      subTitle: 'Rolls Royce',
      image: 'assets/BannerAssets/rollsroyc.jpeg',
      category: 'cars',
      link: '/shop/collection/cars'
    },
    {
      title: 'Clifton',
      subTitle: 'Beyond Villa',
      image: 'assets/images/Banners/beyondHouse.jpeg',
      category: 'houses',
      link: '/shop/collection/houses'
    },
    {
    title: '2020',
    subTitle: 'McLaren',
    image: 'assets/BannerAssets/McLarenArtura.jpeg',
    category: 'cars',
    link: '/shop/collection/cars'
  },
  {
    title: '2022',
    subTitle: 'Aston Martin',
    image: 'assets/images/Banners/astonmartonedition2.jpeg',
    category: 'cars',
    link: '/shop/collection/cars'
  },
  {
    title: 'Johannesburg',
    subTitle: 'Kloof Road House',
    image: 'assets/images/Banners/House1.jpeg',
    category: 'houses',
    link: '/shop/collection/house'
  },
  {
    title: 'S78',
    subTitle: 'Princess Yacht',
    image: 'assets/BannerAssets/s78-ext.jpeg',
    category: 'yachts',
    link: '/shop/collection/yachts'
  },
  {
    title: 'V & A Waterfront',
    subTitle: 'Minosa: One and Only Penthouse',
    image: 'assets/images/Banners/waterfront.jpeg',
    category: 'houses',
    link: '/shop/collection/house'
  }];

  // Collection banner TODO: Image & Link
  public collections = [{
    image: 'assets/images/Banners/Car1.jpg',
    // save: 'save 50%',
    title: 'Cars',
    link: '/shop/collection/newcars'
  }, {
    image: 'assets/images/Banners/aircraft.jpeg',
    // save: 'save 50%',
    title: 'Aircrafts',
    link: '/shop/collection/aircrafts'
  }, {
    image: 'assets/images/Banners/Boat1.jpg',
    // save: 'save 50%',
    title: 'Yachts',
    link: '/shop/collection/yachts'
  }, {
    image: 'assets/images/Banners/house.jpeg',
    // save: 'save 50%',
    title: 'Houses',
    link: '/shop/collection/houses'
  }, {
    image: 'assets/images/Banners/Jewellery1.jpg',
    // save: 'save 50%',
    title: 'Jewellery',
    link: '/shop/collection/jewellery'
  }]

  // Blog
  // public blogs = [{
  //   image: 'assets/images/thumbnails/992Review.jpg',
  //   date: '25 January 2021',
  //   title: 'Porsche - 911 Turbo S Review',
  //   author: 'John Dio'
  // }, {
  //   image: 'assets/images/thumbnails/M2CS.jpg',
  //   date: '26 January 2021',
  //   title: 'BMW - M2 CS Review',
  //   author: 'John Dio'
  // }, {
  //   image: 'assets/images/thumbnails/AMG45s.jpg',
  //   date: '27 January 2021',
  //   title: 'Mercedes Benz - AMG 45S Review',
  //   author: 'John Dio'
  // }, {
  //   image: 'assets/images/blog/14.jpg',
  //   date: '28 January 2018',
  //   title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
  //   author: 'John Dio'
  // }]

  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#0f3079');
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }

  // Product Tab collection
  // getCollectionProducts(collection) {

  //   let FilteredCollection = this.products.filter((item) => {
  //     if (item.collection.find(i => i === collection)) {
  //       return item
  //     }
  //   });

  //   // console.log(FilteredCollection);
  //   return FilteredCollection;
  // }

}
