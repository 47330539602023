<!--modal popup start-->
<ng-template class="theme-modal" #financeSalesPerson let-modal>
    <div class="modal-content">
        <div class="modal-body modal13">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <div class="age-content">
                                <h2>Apply for Finance</h2>
                                <h4>Please start by selecting the Finance Representative you would like assistance from below.</h4>
                                <form id="demoForm">
                                    <mat-form-field appearance="fill" *ngIf="brandsList">
                                        <mat-label>Brand</mat-label>
                                        <mat-select (selectionChange)="brandEvent($event)">
                                          <mat-option *ngFor="let topping of brandsList" [value]="topping.id">{{topping.brand}}</mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                      <p *ngIf="!dropdown && loading">Hold on one second...</p>
                                      <ng-container *ngIf="dropdown">
                                          <mat-form-field appearance="fill">
                                            <mat-label>Sales Person</mat-label>
                                            <mat-select (selectionChange)="salesPersonEvent($event)">
                                              <mat-option *ngFor="let salesPerson of salesPeople" [value]="salesPerson">{{salesPerson.name}}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                      </ng-container>
                                </form>
                                <button type="submit" [disabled]="!valid" ngbAutofocus (click)="submit()" class="btn btn-solid">
                                    submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->