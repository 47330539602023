<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="house.new">new</span> -->
      <!-- <span class="lable4" *ngIf="house.sale">on sale</span> -->
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product/house/', house?.title.replace(' ', '-')]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" [lazyLoad]="ImageSrc "
          class="img-fluid lazy-loading" style="max-width: 250px;" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/house/', house?.title.replace(' ', '-')]">
        <img [src]="ImageSrc " class="img-fluid lazy-loading">
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <hr>
      <h6>{{ house.area }}, {{house.city}}</h6>
      <a [routerLink]="['/shop/product/house/', house?.title.replace(' ', '-')]">
        <h4>{{ house?.title | titlecase }}</h4>
      </a>
      <h5><span class="money">R{{house?.price}}</span></h5>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>