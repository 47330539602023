import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NeedsAnalysesService } from 'src/app/shared/services/needsAnalyses.service';
import { StaffService } from 'src/app/shared/services/staff.service';

@Component({
  selector: 'app-enquiry-modal',
  templateUrl: './enquiry-modal.component.html',
  styleUrls: ['./enquiry-modal.component.scss']
})
export class EnquiryModalComponent implements OnInit, OnDestroy {

  @ViewChild("enquiryModal") EnquiryModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public product: any;
  public enquiryForm: FormGroup;
  public currdate: any;
  public setDate: any;
  public productType: string;
  public header: string;
  public entityLink: string;
  public showDescriptionBox = false;
  public salesPeople = [];
  foods = [
    { value: 'steak-0', viewValue: 'Steak', img: 'https://www.akberiqbal.com/favicon-32x32.png' },
    { value: 'pizza-1', viewValue: 'Pizza', img: 'https://www.akberiqbal.com/favicon-16x16.png' },
    { value: 'tacos-2', viewValue: 'Tacos', img: 'https://www.akberiqbal.com/favicon-96x96.png' }
  ];


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    public needsService: NeedsAnalysesService,
    public staffService: StaffService
  ) {
    this.productType = this.route.snapshot.url[1] ? this.route.snapshot?.url[1]?.toString() : '';
    // TODO: ASSIGN VEHICLE ID BASED OFF PRODUCT TYPE
    // // console.log(this.productType);
    // // console.log(this.product);

    this.enquiryForm = this.fb.group({
      customer_name: ['', Validators.required],
      customer_surname: ['', Validators.required],
      customer_email: ['', Validators.required],
      customer_number: ['', Validators.required],
      enquiry_description: [''],
      dealer_id: [''],
      user_id: [''],
    })
  }

  ngOnInit(): void {

  }


  openModal(Product) {
    this.product = Product;
    switch (this.productType) {
      case 'new-car':
        this.header = this.product.title ? this.product.title : this.product.brand;
        break;
      case 'used-car':
        this.header = this.product.brand + ' ' + this.product.model;
        break;
      case 'aircraft':
        this.header = this.product.model;
        break;
      case 'yacht':
        this.header = this.product.model;
        break;
      case 'house':
        this.header = this.product.title;
        break;
      case 'jewellery':
        this.header = this.product.title;
        break;
      default:
        break;
    }
    if (this.product?.dealer) { 
      this.staffService.getDealerStaffAccount(this.product?.dealer)
        .subscribe((res) => {
          // // console.log(res);
          this.salesPeople = res;
          // console.log(this.salesPeople)
        })
    } else if(this.product?.dealer_id) {
      this.staffService.getDealerStaffAccount(this.product?.dealer_id)
        .subscribe((res) => {
          // // console.log(res);
          this.salesPeople = res;
          // console.log(this.salesPeople)
        })
    }
    
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.EnquiryModal, {
        size: 'lg',
        ariaLabelledBy: 'Enquiry-Modal',
        backdrop: 'static',
        centered: true,
        windowClass: 'bd-example-modal-md theme-modal'
      }).result.then((result) => {
        // // console.log(result);
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  buyNowOrLaterEvent($event: any) {
    const showDetails = $event === 'justenquiring';
    this.showDescriptionBox = showDetails;
  }

  salesPersonEvent($event: any) {
    if ($event === "any"){
      return
    } else {
      this.enquiryForm.patchValue({ user_id: $event });
    }
  }




  ageForm() {
    var day = this.enquiryForm.value.day;
    var month = this.enquiryForm.value.month;
    var year = this.enquiryForm.value.year;
    var age = 18;
    var mydate = new Date();
    mydate.setFullYear(year, month - 1, day);

    var currdate = new Date();
    this.currdate = currdate;
    var setDate = new Date();
    this.setDate = setDate.setFullYear(mydate.getFullYear() + age, month - 1, day);

    if ((this.currdate - this.setDate) > 0) {
      localStorage.setItem('enquiryModal', 'true')
      this.modalService.dismissAll();
    } else {
      window.location.href = "https://www.google.com/";
    }
  }

  submitForm() {
    switch (this.productType) {
      case 'used-car':
        this.enquiryForm.addControl('vehicle_id', new FormControl(this.product.id));
        this.entityLink = '/usedCarEnquiries/';
        break;
      case 'new-car':
        this.enquiryForm.addControl('vehicle_id', new FormControl(this.product.id));
        this.entityLink = '/newCarEnquiries/';
        break;
      case 'aircraft':
        this.enquiryForm.addControl('aircraft_id', new FormControl(this.product.id));
        this.entityLink = '/aircraftCarEnquiries/';
        break;
      case 'yacht':
        this.enquiryForm.addControl('vehicle_id', new FormControl(this.product.id));
        this.entityLink = '/yachtEnquiries/';
        break;
      case 'house':
        this.enquiryForm.addControl('house_id', new FormControl(this.product.id));
        this.entityLink = '/houseEnquiries/';
        break;
      case 'jewellery':
        this.enquiryForm.addControl('jewellery_id', new FormControl(this.product.id));
        this.entityLink = '/jewelleryEnquiries/';
        break;
      default:
        break;
    }
    this.enquiryForm.get('dealer_id').setValue(this.product.dealer ? this.product.dealer : this.product.dealer_id);

    if (this.enquiryForm.invalid) {
      return
    } else {
      const data = Object.assign({}, this.enquiryForm.value);
      // // console.log('data sent out:', data)
      this.needsService.create(data, this.entityLink).subscribe(res => {
        this.entityRouter();
      })
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  entityRouter(){
    this.modalService.dismissAll();
    switch (this.productType) {
      case 'used-car':
        this.router.navigate(['/shop/collection/usedcars']);
        break;
      case 'new-car':
        this.router.navigate(['/shop/collection/newcars']);
        break;
      case 'aircraft':
        this.router.navigate(['/shop/collection/aircrafts']);
        break;
      case 'yacht':
        this.router.navigate(['/shop/collection/yachts']);
        break;
      case 'house':
        this.router.navigate(['/shop/collection/houses']);
        break;
      case 'jewellery':
        this.router.navigate(['/shop/collection/jewellery']);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }

  }

}
