import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef,
  Injectable, PLATFORM_ID, Inject, Input
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit, OnDestroy {

  @ViewChild("videoModal", { static: false }) VideoModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  videoLink;
  @Input() videoData;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    const _videoLink = this.videoData;
    this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(_videoLink);
  }

  openModal(idk?) {

    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      if (idk) {
        this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(idk);
        this.modalService.open(this.VideoModal, {
          size: 'lg',
          ariaLabelledBy: 'Video-Modal',
          centered: true,
          windowClass: 'modal fade video-modal'
        }).result.then((result) => {
          `Result ${result}`
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }


}
