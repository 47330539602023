import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinanceLocalStorageService {
  
  financePersonObjValue = new BehaviorSubject(this.financePersonObj);

  set financePersonObj(value) {
    this.financePersonObjValue.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('financePersonObj', value);
  }
 
  get financePersonObj() {
    return localStorage.getItem('financePersonObj');
  }
}
