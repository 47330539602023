import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/home', title: 'home', type: 'link',
		},
		{
			title: 'About', type: 'sub', active: false, children: [
				{ path: '/pages/aboutus', title: 'About', type: 'link' },
				{ path: '/pages/gallery', title: 'Gallery', type: 'link' },
				{ path: '/pages/contact', title: 'Contact', type: 'link' },
			]
		},
		{
			title: 'Showroom', type: 'sub', active: false, children: [
				{
					title: 'Cars', type: 'sub', active: false, children: [
						{ path: '/shop/collection/newcars', title: 'New Cars', type: 'link' },
						{ path: '/shop/collection/usedcars', title: 'Used Cars', type: 'link' },
					]
				},
				{ path: '/shop/collection/aircrafts', title: 'Aircrafts', type: 'link' },
				{ path: '/shop/collection/houses', title: 'Houses', type: 'link' },
				{ path: '/shop/collection/yachts', title: 'Yatchts', type: 'link' },
				{ path: '/shop/collection/jewellery', title: 'Jewellery', type: 'link' },
			]
		},
		{
			path: `/pages/blog/Mhondoro-Safari Lodge & Villa`, title: 'Competition', type: 'link',
		},
		{
			title: 'Apply for Finance', type: 'sub', active: false, children: [
				{ path: '/pages/personal-finance', title: 'Personal', type: 'link' },
				{ path: '#', title: 'Business', type: 'link' },
			]
		},
	];

	OldLeftMenu: Menu[] = [
		{
			title: 'Cars', type: 'sub', active: false, children: [
				{
					title: 'Used Cars', type: 'sub', active: false, children: [
						{ path: '/shop/collection/cars', title: 'View All', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Audi', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Aston Martin', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Royce Royce', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Maserati', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Volkswagen', type: 'link' }
					]
				},
				{
					title: 'New Cars', type: 'sub', active: false, children: [
						{ path: '/shop/collection/newcars', title: 'View All', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Audi', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Aston Martin', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Royce Royce', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Maserati', type: 'link' },
						// { path: '/shop/collection/cars', title: 'Volkswagen', type: 'link' }
					]
				}, // TODO: ACTUAL NEW CARS
				// { path: '/shop/collection/cars', title: 'New Car Showroom', type: 'link' },
				// { path: '/shop/collection/cars', title: 'Book A Test Drive', type: 'link' },
			]
		},
		{
			title: 'Aircrafts', type: 'sub', active: false, children: [
				{ path: '/shop/collection/aircrafts', title: 'View All', type: 'link' },
				// {
				// 	// TODO: LIST OF ALL FLEETS CATEGORY
				// 	title: 'Fleet Category', type: 'sub', active: false, children: [
				// 		{ path: '/shop/collection/aircrafts', title: 'Beechcraft', type: 'link' },
				// 		{ path: '/shop/collection/aircrafts', title: 'Bombardier', type: 'link' },
				// 		{ path: '/shop/collection/aircrafts', title: 'British Aerospace', type: 'link' },
				// 		{ path: '/shop/collection/aircrafts', title: 'Cessna', type: 'link' },
				// 		{ path: '/shop/collection/aircrafts', title: 'Gulfstream', type: 'link' },
				// 		{ path: '/shop/collection/aircrafts', title: 'Helicopters', type: 'link' },
				// 		{ path: '/shop/collection/aircrafts', title: 'Nextant', type: 'link' },
				// 		{ path: '/shop/collection/aircrafts', title: 'Pilatus', type: 'link' },
				// 	]
				// }
			]
		},
		{
			title: 'Houses', type: 'sub', active: false, children: [
				{ path: '/shop/collection/houses', title: 'View All', type: 'link' },
				// {
				// 	// TODO: LIST OF ALL FLEETS CATEGORY
				// 	title: 'Area', megaMenu: true, type: 'sub', active: false, children: [
				// 		{ path: '/shop/collection/houses', title: 'Bantry Bay', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Camps Bay', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Bakoven', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Clifton', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Fresnaye', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Waterfront', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Llandudno', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Constantia', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Stellenbosch', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Franschhoek', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Paarl', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Durbanville', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Boggoms Baai', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Plettenberg Bay', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Knysna', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Montagu', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Keurbooms', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'Stanford', type: 'link' },
				// 		{ path: '/shop/collection/houses', title: 'St Helena Bay', type: 'link' },
				// 	]
				// }
			]
		},
		{
			path: '/shop/collection/yachts', title: 'Yatchts', type: 'link',
		},
		{
			path: '/shop/collection/jewellery', title: 'Jewellery', type: 'link',
		},
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.OldLeftMenu);

}
