<!-- <app-header-one [themeLogo]="themeLogo"></app-header-one> -->
<app-header-two [themeLogo]="themeLogo"></app-header-two>
<!-- Home slider start-->
<section class="p-0">
  <app-slider 
  [sliders]="sliders" 
  [textClass]="'text-center'"
  [category]="'furniture'"
  [buttonText]="'browse services'"
  [buttonLink]='sliders'> 
</app-slider>
</section>
<!-- Home slider End -->

<!-- TODO: Michael, filter search -->
<app-search></app-search> 

<!-- collection banner -->
<div class="title1 section-t-space">
  <h2>Receive High End Concierge Services</h2>
  <h4 class="title-inner1">Provided By The Doctors Club</h4>
</div>
<section class="banner-padding banner-furniture">
    <div class="container-fluid">
        <div class="row partition4">
          <div class="col" *ngFor="let collection of collections">
                <a [routerLink]="[collection.link]">
                    <div class="collection-banner p-right text-right">
                        <img [src]="collection.image" alt="collection" class="img-fluid">
                         <div class="contain-banner banner-4">
                            <div>
                                <h4>{{collection.save}}</h4>
                                <h2>{{collection.title}}</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- collection banner end -->

<!-- Tab product start-->
<!-- <div class="title1 section-t-space">
  <h2>Exclusive Concierge Services</h2>
  <h4 class="title-inner1">Provided By The Doctors Club</h4>
</div>
<section class="section-b-space p-t-0">
  <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">
          <ngb-tab *ngFor="let collection of productCollections">
            <ng-template ngbTabTitle>{{collection}}</ng-template>
            <ng-template ngbTabContent>
              <div class="no-slider row">
                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                  <div class="product-box">
                    <app-product-box-one 
                      [product]="product"
                      [currency]="productService?.Currency">    
                    </app-product-box-one>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</section> -->
<!-- Tab product End -->

<!-- Video section start -->
<section class="video-section section-b-space">
  <div class="title1 ">
      <h4>Rated one of the best in vehicle innovation it is no surprise</h4>
      <h2 class="title-inner1">"The future begins with us"</h2>
  </div>
  <div class="container">
      <div class="row">
          <div class="col-md-8 offset-md-2">
              <a href="javascript:void(0)">
                  <div class="video-img">
                      <img src="assets/YoutubeThumbnail.jpeg" alt="" class="img-fluid">
                      <div class="play-btn" (click)="videoModal.openModal(_videoLink)">
                          <span><i class="fa fa-play" aria-hidden="true"></i></span>
                      </div>
                  </div>
              </a>
          </div>
      </div>
  </div>
</section>
<!-- video section end -->

<!-- Parallax banner -->
<section class="p-0">
  <div class="full-banner parallax-banner3 parallax text-center p-left"
      [ngStyle]="{'background-image': 'url(assets/images/parallax/Background.shrink.jpg)'}">
      <div class="container">
          <div class="row">
              <div class="col">
                  <div class="banner-contain">
                      <h2>DC My Vehicle</h2>
                      <h3>Download Mobile App</h3>
                      <h4>The Future Begins With Us</h4>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- Parallax banner end -->

<!-- blog section start-->
<section class="blog p-t-0 section-b-space">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!-- Cart Open To Top Side-->
<app-cart-variation [direction]="'top'"></app-cart-variation> 
<!-- <app-footer-one [themeLogo]="themeLogo"></app-footer-one> -->
<app-enquiry-modal #enquiryModal></app-enquiry-modal>
<app-video-modal #videoModal></app-video-modal>

<app-footer-four [class]="'footer-light pet-layout-footer'" [themeLogo]="themeLogo"></app-footer-four>