import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentCustomerSubject: BehaviorSubject<User>;
    public currentCustomer: Observable<User>;
    private url = `${environment.apiUrl}`;

    private readonly httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      };

    constructor(private http: HttpClient) {
        this.currentCustomerSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentCustomer')));
        this.currentCustomer = this.currentCustomerSubject.asObservable();
    }

    public get currentCustomerValue(): User {
        return this.currentCustomerSubject.value;
    }

    login(email, password) {
        return this.http.post<any>(this.url + '/login/user', { email, password, returnSecureToken: true })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentCustomer', JSON.stringify(user));
                this.currentCustomerSubject.next(user);
                return user;
            }));
    }

    loginPromise(email, password): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post<any>(this.url + '/login/user', { email, password, "returnSecureToken": true })
                .subscribe(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentCustomer', JSON.stringify(user));
                    this.currentCustomerSubject.next(user);
                    resolve(user);
                    return user;
                }, error => {
                    reject(error);
                });
        });
    }
    
    createUser(registrationForm): Promise<any> {
      debugger;
        // create Doctor Accounts for sign up
        return new Promise((resolve, reject) => {
          this.http.post<any>(this.url + '/signup/user', registrationForm)
              .subscribe(user => {
                resolve(user);
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  // localStorage.setItem('currentCustomer', JSON.stringify(user));
                  // this.currentCustomerSubject.next(user);
                  // resolve(user);
                  return user;
              }, error => {
                  reject(error);
              });
      });
    }

    verifyEmail(token, userId): Promise<any> {
      return new Promise((resolve, reject) => {
        this.http.get<any>(this.url + `/confirmemail/${token}/${userId}`)
        .subscribe(response => {
          resolve(response);
        },
        error => {
          reject(error)
        })
      })
    }
    

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentCustomer');
        localStorage.removeItem('dealerAccount');
        this.currentCustomerSubject.next(null);
        // this.router.navigate(['/login']);
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
      }
}