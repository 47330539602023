import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Yacht } from '../classes/yacht';
import { environment } from "../../../environments/environment";

const state = {
    yachts: JSON.parse(localStorage['yachts'] || '[]'),
    wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
    compare: JSON.parse(localStorage['compareItems'] || '[]'),
    cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
    providedIn: 'root'
})
export class YachtService {

    public Currency = { name: 'Rands', currency: 'R', price: 1 } // Default Currency
    public OpenCart: boolean = false;
    public Yachts;
    public key;

    constructor(private http: HttpClient,
        private toastrService: ToastrService) { }

    /*
      ---------------------------------------------
      ---------------  Product  -------------------
      ---------------------------------------------
    */

    // Product
    private get yachts(): Observable<Yacht[]> {
        this.Yachts = this.http.get<Yacht[]>(environment.apiUrl + '/yachtsFrontend').pipe(map(data => data));
        this.Yachts.subscribe(next => { localStorage['yachts'] = JSON.stringify(next) });
        // console.log(this.Yachts)
        return this.Yachts = this.Yachts.pipe(startWith(JSON.parse(localStorage['yachts'] || '[]')));
    }

    // Get Yachts
    public get getYachts(): Observable<Yacht[]> {
        return this.yachts;
    }

    // Get Yachts By Slug
    public getYachtBySlug(slug: string): Observable<Yacht> {
        return this.yachts.pipe(map(items => {
            return items.find((item: any) => {
                return item.model.replace(' ', '-') === slug;
            });
        }));
    }


    /*
      ---------------------------------------------
      ---------------  Wish List  -----------------
      ---------------------------------------------
    */

    // Get Wishlist Items
    public get wishlistItems(): Observable<Yacht[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.wishlist);
            observer.complete();
        });
        return <Observable<Yacht[]>>itemsStream;
    }

    // Add to Wishlist
    public addToWishlist(yacht): any {
        const wishlistItem = state.wishlist.find(item => item.id === yacht.id)
        if (!wishlistItem) {
            state.wishlist.push({
                ...yacht
            })
        }
        this.toastrService.success('Yacht has been added in wishlist.');
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true
    }

    // Remove Wishlist items
    public removeWishlistItem(yacht: Yacht): any {
        const index = state.wishlist.indexOf(yacht);
        state.wishlist.splice(index, 1);
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true
    }

    /*
      ---------------------------------------------
      -------------  Compare Product  -------------
      ---------------------------------------------
    */

    // Get Compare Items
    public get compareItems(): Observable<Yacht[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.compare);
            observer.complete();
        });
        return <Observable<Yacht[]>>itemsStream;
    }

    // Add to Compare
    public addToCompare(yacht): any {
        const compareItem = state.compare.find(item => item.id === yacht.id)
        if (!compareItem) {
            state.compare.push({
                ...yacht
            })
        }
        this.toastrService.success('Product has been added in compare.');
        localStorage.setItem("compareItems", JSON.stringify(state.compare));
        return true
    }

    // Remove Compare items
    public removeCompareItem(yacht: Yacht): any {
        const index = state.compare.indexOf(yacht);
        state.compare.splice(index, 1);
        localStorage.setItem("compareItems", JSON.stringify(state.compare));
        return true
    }

    /*
      ---------------------------------------------
      -----------------  Cart  --------------------
      ---------------------------------------------
    */

    // Get Cart Items
    public get cartItems(): Observable<Yacht[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.cart);
            observer.complete();
        });
        return <Observable<Yacht[]>>itemsStream;
    }

    // Add to Cart
    public addToCart(yacht): any {
        const cartItem = state.cart.find(item => item.id === yacht.id);
        const qty = yacht.quantity ? yacht.quantity : 1;
        const items = cartItem ? cartItem : yacht;
        const stock = this.calculateStockCounts(items, qty);

        if (!stock) return false

        if (cartItem) {
            cartItem.quantity += qty
        } else {
            state.cart.push({
                ...yacht,
                quantity: qty
            })
        }

        this.OpenCart = true; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
    }

    // Update Cart Quantity
    public updateCartQuantity(yacht: Yacht, quantity: number): Yacht | boolean {
        return state.cart.find((items, index) => {
            if (items.id === yacht.id) {
                const qty = state.cart[index].quantity + quantity
                const stock = this.calculateStockCounts(state.cart[index], quantity)
                if (qty !== 0 && stock) {
                    state.cart[index].quantity = qty
                }
                localStorage.setItem("cartItems", JSON.stringify(state.cart));
                return true
            }
        })
    }

    // Calculate Stock Counts
    public calculateStockCounts(yacht, quantity) {
        const qty = yacht.quantity + quantity
        const stock = yacht.stock
        if (stock < qty || stock == 0) {
            this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
            return false
        }
        return true
    }

    // Remove Cart items
    public removeCartItem(yacht: Yacht): any {
        const index = state.cart.indexOf(yacht);
        state.cart.splice(index, 1);
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
    }

    // Total amount 
    // public cartTotalAmount(): Observable<number> {
    //     return this.cartItems.pipe(map((yacht: Yacht[]) => {
    //         return yacht.reduce((prev, curr: Yacht) => {
    //             let price = curr.price;
    //             if (curr.discount) {
    //                 price = curr.price - (curr.price * curr.discount / 100)
    //             }
    //             return (prev + price * curr.quantity) * this.Currency.price;
    //         }, 0);
    //     }));
    // }

    /*
      ---------------------------------------------
      ------------  Filter Product  ---------------
      ---------------------------------------------
    */

    // Get Product Filter
    public filterYachts(filter: any): Observable<Yacht[]> {
        return this.yachts.pipe(map(yacht =>
            yacht.filter((item: any) => {
                if (!filter.length) return true
                const Tags = filter.some((prev) => { // Match Tags
                    // // console.log('PREV IS HERE: ' + prev);
                    // // console.log('ITEMTYPE IS HERE: ' + item.brand);

                    this.key = Object.keys(item).find(key => item[key] === prev);
                    if (this.key) {
                        return prev
                    }
                })
                return Tags
            })
        ));
    }

    // Sorting Filter
    // public sortYachts(yacht: Yacht[], payload: string): any {

    //     if (payload === 'ascending') {
    //         return yacht.sort((a, b) => {
    //             if (a.id < b.id) {
    //                 return -1;
    //             } else if (a.id > b.id) {
    //                 return 1;
    //             }
    //             return 0;
    //         })
    //     } else if (payload === 'a-z') {
    //         return yacht.sort((a, b) => {
    //             if (a.title < b.title) {
    //                 return -1;
    //             } else if (a.title > b.title) {
    //                 return 1;
    //             }
    //             return 0;
    //         })
    //     } else if (payload === 'z-a') {
    //         return yacht.sort((a, b) => {
    //             if (a.title > b.title) {
    //                 return -1;
    //             } else if (a.title < b.title) {
    //                 return 1;
    //             }
    //             return 0;
    //         })
    //     } else if (payload === 'low') {
    //         return yacht.sort((a, b) => {
    //             if (a.price < b.price) {
    //                 return -1;
    //             } else if (a.price > b.price) {
    //                 return 1;
    //             }
    //             return 0;
    //         })
    //     } else if (payload === 'high') {
    //         return yacht.sort((a, b) => {
    //             if (a.price > b.price) {
    //                 return -1;
    //             } else if (a.price < b.price) {
    //                 return 1;
    //             }
    //             return 0;
    //         })
    //     }
    // }

    /*
      ---------------------------------------------
      ------------- Yachts Pagination  -----------
      ---------------------------------------------
    */
    public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // Paginate Range
        let paginateRange = 8;

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange - 1) {
            startPage = 1;
            endPage = startPage + paginateRange - 1;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

}
