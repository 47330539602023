<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="yacht.new">new</span> -->
      <!-- <span class="lable4" *ngIf="yacht.sale">on sale</span> -->
    </div> 
    <div class="front">
      <a [routerLink]="['/shop/product/yacht/', yacht?.model.replace(' ', '-')]">
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc " 
          class="img-fluid lazy-loading" 
          /> 
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <!-- <a [routerLink]="['/shop/product/used-car/', product.model.replace(' ', '-')]"> -->
        <img [src]="ImageSrc " class="img-fluid lazy-loading" >
      <!-- </a> -->
    </div>
    <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul> -->
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(yacht)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(yacht)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(yacht)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(yacht)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
      <a [routerLink]="['/shop/product/yacht/', yacht?.model.replace(' ', '-')]">
        <h5>{{ yacht?.model | titlecase }}</h5>
      </a>
      <!-- <p>{{ yacht?.description }}</p> -->
      <!-- <h4> -->
        <!-- {{ yacht?.price * currency?.price | discount:yacht | currency:currency?.currency:'symbol' }} -->
        <!-- <del *ngIf="yacht?.discount"><span class="money"> {{ yacht?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del> -->
      <!-- </h4> -->
      <span class="money"> Engine - {{yacht?.engines}}</span>
      <!-- <ul class="color-variant" *ngIf="Color(yacht?.variants).length">
        <li [class]="color" *ngFor="let color of Color(yacht?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, yacht)">
        </li>
      </ul> -->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<!-- <app-quick-view #quickView [product]="yacht" [currency]="currency"></app-quick-view> -->
<!-- <app-cart-modal #cartModal [product]="yacht" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->

