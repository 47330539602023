<!-- <app-breadcrumb [title]="'Search'" [breadcrumb]="'Search'"></app-breadcrumb> -->
<!-- section start -->
<section class="authentication-page section-b-space">
    <section class="search-block">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <form class="form-header">
              <div class="form-group">
                <input (change)='search($event)' type="text" class="form-control" id="exampleInputPassword1" placeholder="Search for a Product">
              </div>
              <button type="submit" class="btn btn-outline"><i class="fa fa-search"></i></button>
            </form>
            <!-- TODO: MAKE THE INPUT TO BE BUTTONS, MAKE DYNAMIC ADVANCED SEARCH -->
            <form class="theme-form">
              <div class="form-row">
                <select class="custom-select col-md-3 ml-5" required=""
                (change)='catSelect($event)'>
                  <option value="">Category</option>
                  <option value="1">Yatch</option>
                  <option value="2">Car</option>
                  <option value="3">Aircrafts</option>
                  <option value="4">Jewellery</option>
                  <option value="5">Houses</option>
                </select>
                <select class="custom-select col-md-3 ml-5" required="">
                  <option value="">Dealer</option>
                </select>
                <select class="custom-select col-md-3 ml-5" required="">
                  <option value="">Area</option>
                </select>
              </div>
              <div class="form-row">
              

                <!-- <select class="custom-select col-md-6" required="">
                  <option value="">Price</option>
                  <option value="1">Percent</option>
                  <option value="2">Fixed</option>
                </select> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <ng-container *ngIf="show">

      <table class="table table-striped" >
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">Model</th>
          <th scope="col">Price</th>
          <th scope="col">Category</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let country of searchResults; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <td>
            {{ country.title }}
          </td>
          <td>{{ country.model }}</td>
          <td>{{ country.price }}</td>
          <td>{{ type }}</td>
        </tr>
        </tbody>
      </table>
    </ng-container>
    <!-- <div class="col-sm-12 text-center">
          <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
            <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
            <p>Please check if you have misspelt something or try searching with other words.</p>
            <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">continue shopping</a>
        </div> -->
</section>
<!-- section end -->