<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="car.new">new</span> -->
      <!-- <span class="lable4" *ngIf="car.sale">on sale</span> -->
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product/used-car/', car?.model.replace(' ', '-')]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" [lazyLoad]="ImageSrc "
          class="img-fluid lazy-loading" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/used-car/', car?.model.replace(' ', '-')]">
        <img [src]="ImageSrc " class="img-fluid lazy-loading">
      </a>
    </div>
  </div>
  <br>
  <div class="product-detail">
    <div>
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
      <a [routerLink]="['/shop/product/used-car/', car?.model.replace(' ', '-')]">
        <h6 style="display: inline-block"> {{ car?.brand | titlecase }}</h6>
        <br>
        <h4 style="display: inline-block">{{ car?.model | titlecase }} </h4>
        <!-- <h4>{{ car?.model | titlecase }}</h4> -->
      </a>
      <br>
      <!-- <a [routerLink]="['/shop/product/used-car/', car?.model.replace(' ', '-')]">
        <h6>{{ car?.brand | titlecase }}</h6>
      </a> -->
      <!-- <p>{{ car?.description }}</p> -->

      <span class="money">
        <mat-icon>account_balance_wallet</mat-icon>
        R{{ car?.price }}
      </span>
      <br>
      <span class="money">
        <mat-icon>timer</mat-icon>
        {{car?.kilos}} KMs
      </span>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<!-- <app-quick-view #quickView [product]="car" [currency]="currency"></app-quick-view> -->
<!-- <app-cart-modal #cartModal [product]="car" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->