import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {

  transform(input: any): any {
    if (!this.isString(input)) {
      return input;
    }

    return input.trim();
  };

  isString(value: any): value is string {
    return typeof value === 'string';
  }
}

// export function isString(value: any): value is string {
//   return typeof value === 'string';
// }