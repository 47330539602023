<div *ngIf="!loader">
    <div class="img-wrapper">
      <div class="lable-block">
        <!-- <span class="lable3" *ngIf="aircraft.new">new</span> -->
        <!-- <span class="lable4" *ngIf="aircraft.sale">on sale</span> -->
      </div> 
      <div class="front">
        <a [routerLink]="['/shop/product/aircraft/', aircraft?.model.replace(' ', '-')]">
          <img 
            [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
            [lazyLoad]="ImageSrc " 
            class="img-fluid lazy-loading" 
            /> 
        </a>
      </div>
      <div class="back" *ngIf="onHowerChangeImage">
        <!-- <a [routerLink]="['/shop/product/used-car/', product.model.replace(' ', '-')]"> -->
          <img [src]="ImageSrc " class="img-fluid lazy-loading" >
        <!-- </a> -->
      </div>
    </div>
    <div class="product-detail">
      <div>
        <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
        <a [routerLink]="['/shop/product/aircraft/', aircraft?.model.replace(' ', '-')]">
          <h5>{{ aircraft?.model | titlecase }}</h5>
        </a>
        <!-- <p>{{ aircraft?.description }}</p> -->
        <h4>
          <!-- {{ aircraft?.price * currency?.price | discount:aircraft | currency:currency?.currency:'symbol' }} -->
          <!-- <del *ngIf="aircraft?.discount"><span class="money"> {{ aircraft?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del> -->
        </h4>
        <span class="money">{{aircraft?.yom}} - PAX: {{aircraft?.pax}} - INS: {{ aircraft?.insurance_cover }}</span>
        <!-- <ul class="color-variant" *ngIf="Color(aircraft?.variants).length">
          <li [class]="color" *ngFor="let color of Color(aircraft?.variants)" [ngStyle]="{'background-color': color}"
            (click)="ChangeVariants(color, aircraft)">
          </li>
        </ul> -->
      </div>
    </div>
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  <app-quick-view #quickView [product]="aircraft" [currency]="currency"></app-quick-view>
  <app-cart-modal #cartModal [product]="aircraft" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
  
  