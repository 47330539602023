import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../../modal/cart-modal/cart-modal.component";
import { Car } from "../../../../classes/car";
import { NewCarService } from "../../../../services/newcar.service";
// import {  } from "../../../../uploads/";

@Component({
  selector: 'app-new-car-product-box',
  templateUrl: './new-car-product-box.component.html',
  styleUrls: ['./new-car-product-box.component.scss']
})
export class NewCarProductBoxComponent implements OnInit {

 
  @Input() car: Car;
  @Input() currency: any = this.carService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string

  constructor(private carService: NewCarService) {
  }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.ImageSrc = this.car.media?.[0]?.medium?.url;
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  // ChangeVariants(color, car) {
  //   car.variants.map((item) => {
  //     if (item.color === color) {
  //       car.images.map((img) => {
  //         if (img.image_id === item.image_id) {
  //           this.ImageSrc = img.src;
  //         }
  //       })
  //     }
  //   })
  // }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.carService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.carService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.carService.addToCompare(product);
  }

}