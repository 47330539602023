import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../../modal/cart-modal/cart-modal.component";
import { Aircraft } from "../../../../classes/aircraft";
import { AircraftService } from "../../../../services/aircraft.service";
// import {  } from "../../../../uploads/";

@Component({
  selector: 'app-aircraft-product-box',
  templateUrl: './aircraft-product-box.component.html',
  styleUrls: ['./aircraft-product-box.component.scss']
})
export class AircraftProductBoxComponent implements OnInit {

 
  @Input() aircraft: Aircraft;
  @Input() currency: any = this.aircraftService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string

  constructor(private aircraftService: AircraftService) { }

  ngOnInit(): void {
    // // console.log(this.aircraft)
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
      this.ImageSrc = this.aircraft?.media?.[0]?.medium?.url; 
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, aircraft) {
    aircraft.variants.map((item) => {
      if (item.color === color) {
        aircraft.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.aircraftService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.aircraftService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.aircraftService.addToCompare(product);
  }

}