import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/auth/_models/user';
import { AuthenticationService } from 'src/app/auth/_service/authentication.service';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})
export class HeaderTwoComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/OriginalDCIcon.webp'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  loggedIn;
  
  public stick: boolean = false;
  currentCustomer: User;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,


  ) { }

  ngOnInit(): void {
    this.loggedIn = this.authenticationService.currentCustomerValue ? true : false;
    this.authenticationService.currentCustomer.subscribe(x => {
      this.currentCustomer = x;
    });
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  logout(){
      this.authenticationService.logout();
      this.router.navigate(['/home']);
      location.reload();
  }
}
