<div *ngIf="!loader">
    <div class="img-wrapper">
        <div class="lable-block">
            <!-- <span class="lable3" *ngIf="jewellery.new">new</span> -->
            <!-- <span class="lable4" *ngIf="jewellery.sale">on sale</span> -->
        </div>
        <div class="front">
            <a [routerLink]="['/shop/product/jewellery/', jewellery?.title.replace(' ', '-')]">
            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" [lazyLoad]="ImageSrc "
                class="img-fluid lazy-loading" />
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <!-- <a [routerLink]="['/shop/product/used-car/', product.model.replace(' ', '-')]"> -->
            <img [src]="ImageSrc " class="img-fluid lazy-loading">
            <!-- </a> -->
        </div>
        <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
        <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
          <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
            <img [lazyLoad]="image.src">
          </a>
        </li>
      </ul> -->
    </div>
    <div class="product-detail">
        <div>
            <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
            <a [routerLink]="['/shop/product/jewellery/', jewellery?.title.replace(' ', '-')]">
            <h5>{{ jewellery?.title | titlecase }}</h5>
            </a>
            <!-- <p>{{ jewellery?.description }}</p> -->
                <span class="money">
                    {{ jewellery?.price }}
                    <!-- <del *ngIf="jewellery?.discount"><span class="money"> {{ jewellery?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del> -->
                </span>
            <!-- <ul class="color-variant" *ngIf="Color(jewellery?.variants).length">
          <li [class]="color" *ngFor="let color of Color(jewellery?.variants)" [ngStyle]="{'background-color': color}"
            (click)="ChangeVariants(color, jewellery)">
          </li> 
        </ul> -->
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="jewellery" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="jewellery" [currency]="currency" *ngIf="cartModal"></app-cart-modal>